import Header from './header';
import Footer from './footer';
import Eachpart from "./eachpart";

import { images } from './importingScript';


const Service1 = () => {
  return (
    <>

      {/* services */}
      <div className="container-xxl py-5" id='service'>
        <div className="container">
        <div className="app">
      <br/>
      <h2>Our Services</h2>
      <br/>
     
    </div>
          <div className="row g-4">
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s" style={{visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp'}}>
              <div className="service-item position-relative h-100">
                <div className="service-text rounded p-5">
                  <div className="btn-square bg-light rounded-circle mx-auto mb-4" style={{width: '64px', height: '64px'}}>
                    <img className="img-fluid" src={images['./icon/icon-5.png']} alt="Icon" />
                  </div>
                  <h5 className="mb-3">Whatsapp SMS</h5>
                  <p className="mb-0">No one have time to read newspaper, signboards, flyers, but everyone has seen the Whatsapp </p>
                  <br/>
                </div>
               
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s" style={{visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp'}}>
              <div className="service-item position-relative h-100">
                <div className="service-text rounded p-5">
                  <div className="btn-square bg-light rounded-circle mx-auto mb-4" style={{width: '64px', height: '64px'}}>
                    <img className="img-fluid" src={images['./icon/icon-6.png']} alt="Icon" />
                  </div>
                  <h5 className="mb-3">Bulk SMS Services</h5>
                  <p className="mb-0">Spark Digital India provides a advance service to get connected to its millions of customers and this too is possible and is just a click away!</p>
                </div>
               
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp'}}>
              <div className="service-item position-relative h-100">
                <div className="service-text rounded p-5">
                  <div className="btn-square bg-light rounded-circle mx-auto mb-4" style={{width: '64px', height: '64px'}}>
                    <img className="img-fluid" src={images['./icon/icon-7.png']} alt="Icon" />
                  </div>
                  <h5 className="mb-3">Voice SMS Services</h5>
                  <p className="mb-0">Voice calls lets you to reach your target audience in the easiest, fastest and most cost effective way. Call into Mobile phones or Landlines anywhere in India.</p>
                </div>
               
              </div>
            </div>
            <br/>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s" style={{visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp'}}>
              <div className="service-item position-relative h-100">
                <div className="service-text rounded p-5">
                  <div className="btn-square bg-light rounded-circle mx-auto mb-4" style={{width: '64px', height: '64px'}}>
                    <img className="img-fluid" src={images['./icon/icon-8.png']} alt="Icon" />
                  </div>
                  <h5 className="mb-3">Bulk Email Services</h5>
                  <p className="mb-0">Spark Digital India provides a advance service to get connected to its millions of customers and this too is possible and is just a click away!</p>
                  <br/></div>
                
                
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s" style={{visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp'}}>
              <div className="service-item position-relative h-100">
                <div className="service-text rounded p-5">
                  <div className="btn-square bg-light rounded-circle mx-auto mb-4" style={{width: '64px', height: '64px'}}>
                    <img className="img-fluid" src={images['./icon/icon-9.png']} alt="Icon" />
                  </div>
                  <h5 className="mb-3">Lead Generation </h5>
                  <p className="mb-0">We are hereby doing our level best to make available daily new latest update mobile and email database in various category and almost all major cities of India.</p>
                </div>
               
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp'}}>
              <div className="service-item position-relative h-100">
                <div className="service-text rounded p-5">
                  <div className="btn-square bg-light rounded-circle mx-auto mb-4" style={{width: '64px', height: '64px'}}>
                    <img className="img-fluid" src={images['./icon/icon-10.png']} alt="Icon" />
                  </div>
                  <h5 className="mb-3">Web Designing Services</h5>
                  <p className="mb-0">RCS Technology as a company, Our Developers are ready to except any challenge to develop a website. Our services includes all type of websites from simple to complex.</p>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </>
  );
}

export default Service1;
