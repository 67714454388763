import Header from '../components/header';
import Footer from '../components/footer';
import Eachpart from "../components/eachpart";
import React, { useState } from 'react';
import './about.css';
import { images } from '../components/importingScript';
import emailjs from 'emailjs-com';
import { faPhone } from '@fortawesome/free-solid-svg-icons';


const Contact = () => {
    const [selectedService, setSelectedService] = useState("");
    const [messageStatus, setMessageStatus] = useState(""); // State to handle the success/error message

    const handleServiceChange = (e) => {
        setSelectedService(e.target.value);
    };

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_53lkj6i', 'template_df62i8b', e.target, 'RrQp3a_1JQwFG2LUL')
            .then((result) => {
                console.log(result.text);
                setMessageStatus("Message sent successfully!");
            }, (error) => {
                console.log(error.text);
                setMessageStatus("Failed to send the message. Please try again.");
            });

        e.target.reset();
    };

    return (
        <>
            <Header />
            <Eachpart pageName="Contact" />

            <div className="contact-container">
                <div className="contact-form">
                    <h2>Enquire Now</h2>
                    {messageStatus && <p className="message-status">{messageStatus}</p>} {/* Display success/error message */}
                    <form onSubmit={sendEmail}>
                        <input type="text" name="name" placeholder="Name" required />
                        <input type="email" name="email" placeholder="Email" required />
                        <input type="tel" name="phone" placeholder="Phone" />
                        
                        <select id="service" name="service" value={selectedService} onChange={handleServiceChange}>
                            <option value="" disabled>--Select a Service--</option>
                            <option value="Whatsapp SMS">Whatsapp SMS</option>
                            <option value="Bulk SMS Service">Bulk SMS Service</option>
                            <option value="Voice SMS Service">Voice SMS Service</option>
                            <option value="Bulk Email Service">Bulk Email Service</option>
                            <option value="Lead Generation Service">Lead Generation Service</option>
                            <option value="Web Designing Service">Web Designing Service</option>
                            {/* Add more options as needed */}
                        </select>
                        
                        <textarea rows="5" name="message" placeholder="Message" required></textarea>
                        <button type="submit">Send Message</button>
                    </form>
                </div>
                <div className="map">
                    <iframe
                        title="Google Maps"
                        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Bangalore%20-%20560097%20,%20karnataka+()&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                        allowFullScreen=""
                        loading="lazy"
                    ></iframe>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Contact;
