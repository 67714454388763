
const Footer = () => {
  return (
    <div>
    <div className="container-fluid bg-dark footer mt-5 py-5 wow fadeIn" data-wow-delay="0.1s" style={{ visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeIn' }}>
  <div className="container py-5">
    <div className="row g-5 justify-content-center text-center" style={{ display: 'flex', justifyContent: 'center' }}>
      <div className="col-lg-3 col-md-6 d-flex flex-column align-items-center">
        <h4 className="text-white mb-4">Our Office</h4>
        <p className="mb-2"><i className="fa fa-map-marker-alt me-3" />Bangalore - 560097, Karnataka</p>
        <p className="mb-2"><i className="fa fa-phone-alt me-3" />+91 7411688788</p>
        <p className="mb-2"><i className="fa fa-envelope me-3" />info@sparkdigitalindia.com</p>
      </div>
      <div className="col-lg-3 col-md-6 d-flex flex-column align-items-center">
        <h4 className="text-white mb-4">Quick Links</h4>
        <a className="btn btn-link" href='/home'>Home</a>
        <a className="btn btn-link" href='/about'>About Us</a>
        <a className="btn btn-link" href='/services'>Our Services</a>
        <a className="btn btn-link" href='/contact'>Contact Us</a>
      </div>
      <div className="col-lg-3 col-md-6 d-flex flex-column align-items-center">
        <h4 className="text-white mb-4">Popular Links</h4>
        <a className="btn btn-link" href='/services'>Whatsapp SMS</a>
        <a className="btn btn-link" href='/services'>Bulk SMS Services</a>
        <a className="btn btn-link" href='/services'>Voice SMS Services</a>
        <a className="btn btn-link" href='/services'>Bulk Email Services</a>
        <a className="btn btn-link" href='/services'>Lead Generation Services</a>
        <a className="btn btn-link" href='/services'>Web Designing Services</a>
      </div>
    </div>
  </div>
</div>

      <div className="container-fluid copyright py-4">
        <div className="container">
          <div className="row">
            <div className="col">
              © <a className="fw-medium text-light" href="#">Spark Digital India</a>, All Right Reserved.
            </div>
            <div className="col fs-6 text-center">
              Developed By <a className="fw-medium text-light" target="_blank" href="https:/teciexsolutions.com">Teciex Solutions Pvt. Ltd.</a> 
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
