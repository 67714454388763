// Services.js
import React from 'react';
import './box.css'; 

const ServiceBox = ({ title, children }) => {
  return (
    <div className="service-box">
      <h2>{title}</h2>
      <div className="service-content">{children}</div>
      <a href='#contact'><button className="enquire-now">Enquire Now</button></a>
    </div>
  );
};

const ServicesContainer = () => {
  return (
    <div className="services-container">
      <ServiceBox title="WhatsApp SMS">
        <ul>
          <li>1500+ Characters Text</li>
          <li>1 Image (2 MB)</li>
          <li>1 PDF or Video (2 MB or 5 MB)</li>
          <li>Buttons Options available</li>
          <li>Virtual Number Delivery</li>
          <li>Bulk Uploading available</li>
          <li>Delivery Timing: 10AM to 6PM (Sunday No Delivery)</li>
          <li>Delivery time: 15 Mins to 2 hours</li>
          <li>Report Generated: After 24 hours</li>
        </ul>
      </ServiceBox>
      <ServiceBox title="Bulk SMS Services">
        <ul>
          <li>Online Web Panel</li>
          <li>Unlimited Validity</li>
          <li>100% Accurate Delivery</li>
          <li>100% Server Uptime</li>
          <li>Sender ID: LM-123456</li>
          <li>Sender ID: TA-SENDID</li>
          <li>Free Test & Demo Account</li>
          <li>100% DND Refund</li>
          <li>Delivery Report</li>
        </ul>
      </ServiceBox>
      <ServiceBox title="Voice SMS Services">
        <ul>
          <li>Give notifications</li>
          <li>Political Campaign promotion</li>
          <li>Marketing products</li>
          <li>Announcements</li>
          <li>EMI Premium Reminder</li>
          <li>Mortgage Broker</li>
          <li>Event notification</li>
          <li>Appointment reminder</li>
          <li>Broadcast your own voice</li>
        </ul>
      </ServiceBox>
      <ServiceBox title="Bulk Email Services">
        <ul>
          <li>100% Server Uptime</li>
          <li>Target fans of your brand</li>
          <li>Delivery Report</li>
          <li>Schedule Email Campaign</li>
          <li>Reduce Overhead Costs</li>
          <li>Wish to all Personally</li>
          <li>Focus on your customers</li>
          <li>Simple to share</li>
          <li>Track Opened Email ID</li>
        </ul>
      </ServiceBox>
    </div>
  );
};

const App = () => {
  return (
    <div className="app">
      <h2>What We Can Do For You?</h2>
      <br/>
      <ServicesContainer />
    </div>
  );
};

export default App;
