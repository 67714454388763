import Header from '../components/header'
import Footer from '../components/footer'
import './client.css'
import Eachpart from "../components/eachpart";
// Import each image individually
import image1 from '../assets/client1.png';

import image3 from '../assets/client3.png';
import image4 from '../assets/client4.gif';

import image6 from '../assets/client6.jpeg';
import image7 from '../assets/client7.png';
import image8 from '../assets/client8.gif';
import image9 from '../assets/client9.jpeg';
import image10 from '../assets/client10.jpeg';
import image11 from '../assets/client11.gif';
import image12 from '../assets/client12.gif';
import image13 from '../assets/client13.jpeg';

import image15 from '../assets/client15.jpeg';
import image16 from '../assets/client16.gif';
import image17 from '../assets/client17.jpeg';
import image18 from '../assets/client18.png';
import image19 from '../assets/client19.jpeg';
import image20 from '../assets/client20.jpeg';
import image21 from '../assets/client21.jpeg';
import image22 from '../assets/client22.jpeg';
import image23 from '../assets/client23.jpeg';
import image24 from '../assets/client24.jpeg';
import image25 from '../assets/client25.jpeg';
import image26 from '../assets/client26.jpeg';
import image27 from '../assets/client27.jpeg';
import image28 from '../assets/client28.gif';
import image29 from '../assets/client29.gif';
import image30 from '../assets/client30.gif';
import image31 from '../assets/client31.jpeg';
import image32 from '../assets/client32.jpeg';
import image33 from '../assets/client33.jpeg';
import image34 from '../assets/client34.jpeg';
import image35 from '../assets/client35.png';
import image36 from '../assets/client36.jpeg';
import image37 from '../assets/client37.jpeg';
import image38 from '../assets/client38.jpeg';
import image39 from '../assets/client39.jpeg';
import image40 from '../assets/client40.png';
import image41 from '../assets/client41.jpeg';
import image42 from '../assets/client42.jpeg';

const images = [
  image1, image3,image4,image6,image7, image8,image9,image10, image11,image12,  image13,image15,image16, image17,image18,image19, image20,image21,image22, image23,image24,  image25, image26,image27,image28, image29,image30,image31, image32,image33,image34, image35,image36,  image37, image38,image39,image40, image41,image42
];

const Client = ({ page }) => {
  return (
    <> 
      <Header />
      <Eachpart pageName="Client" />
      <div className="image-grid">
        {images.map((src, index) => (
          <img key={index} src={src} alt={`Image ${index + 1}`} />
        ))}
      </div>
      <Footer />
    </>
  );
}

export default Client;
