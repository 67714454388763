import React, { useState } from 'react';
import './b.css';
import emailjs from 'emailjs-com';

const Contact1 = () => {
    const [selectedService, setSelectedService] = useState("");
    const [messageStatus, setMessageStatus] = useState(""); 

    const handleServiceChange = (e) => {
        setSelectedService(e.target.value);
    };

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_53lkj6i', 'template_df62i8b', e.target, 'RrQp3a_1JQwFG2LUL')
            .then((result) => {
                console.log(result.text);
                setMessageStatus("Message sent successfully!");
            }, (error) => {
                console.log(error.text);
                setMessageStatus("Failed to send the message. Please try again.");
            });

        e.target.reset();
    };
  return (
    <div className="contact-container" id='contact'>
                <div className="contact-form">
                    <h2>Enquire Now</h2>
                    {messageStatus && <p className="message-status">{messageStatus}</p>} {/* Display success/error message */}
                    <form onSubmit={sendEmail}>
                        <input type="text" name="name" placeholder="Name" required />
                        <input type="email" name="email" placeholder="Email" required />
                        <input type="tel" name="phone" placeholder="Phone" />
                        
                        <select id="service" name="service" value={selectedService} onChange={handleServiceChange}>
                            <option value="" disabled>--Select a Service--</option>
                            <option value="Whatsapp SMS">Tele Calling Services</option>
                            <option value="Bulk SMS Service">Bulk SMS Service</option>
                            <option value="Voice SMS Service">Whatsapp SMS Service</option>
                            <option value="Bulk Email Service">Voice Email Service</option>
                            <option value="Lead Generation Service">Bulk Email Service</option>
                            <option value="Web Designing Service">Web Design Service</option>
                            {/* Add more options as needed */}
                        </select>
                        
                        <textarea rows="5" name="message" placeholder="Message" required></textarea>
                        <button type="submit">Send Message</button>
                    </form>
                </div>
                <div className="map">
                    <iframe
                        title="Google Maps"
                        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Bangalore%20-%20560097%20,%20karnataka+()&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                        allowFullScreen=""
                        loading="lazy"
                    ></iframe>
                </div>
            </div>
  );
};

export default Contact1;



